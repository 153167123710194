@import "src/style/variables";
@import "src/style/colors";

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0.5em 0;
}

.title {
  width: 100%;
}

.searchbar {
  padding: 5px 5px;
  outline: none;

  font-size: 1.1em;

  @media (max-width: $md) {
    flex-grow: 1;
  }

  @media (min-width: $md) {
    min-width: 300px;
  }

  @media (min-width: $lg) {
    min-width: 400px;
  }

  border: none;
  border-radius: 0;
  border-bottom: 1px solid $green-dark;
}
