@import "src/style/colors";

.container {
  display: flex;
  border: 2px solid $green-dark;
  border-radius: 10px;
  padding: 1em 1em;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 0;
  }

  img {
    max-width: 500px;
  }
}
