@import "src/style/colors";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 0;

  button {
    background-color: white;
    border: 2px solid $green-dark;
    border-radius: 5px;
    padding: 0.5em 1.5em;
    font-weight: bold;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: $sky-light;

      &:disabled {
        background-color: white;
        color: black;
      }
    }

    &:disabled {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      filter: grayscale(100%);
      cursor: default;
    }
  }
}

.page_number {
  padding: 0 1em;

  .current {
    font-weight: bold;
  }

  .count {
    padding-left: 0.5em;
    font-size: 0.75em;
  }
}
