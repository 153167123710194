@import "src/style/colors";

.header {
  padding: 2em 2em 2em 2em;
  background-color: $sky-light;
  color: $green-dark;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  width: 100%;

  span {
    font-size: 2em;
    font-weight: bold;
    margin-left: 6px;
  }
}

.subtitle {
  text-align: center;
}
