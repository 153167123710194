@import "src/style/colors";

.container {
  img {
    max-width: 600px;
    margin: 0 auto;
  }
}

.hint {
  color: $sky-light;
  font-style: italic;
  margin-bottom: 1em;
}
