.container {
  display: flex;
}

.poster {
  width: 50px;
}

.unknown_poster {
  width: 50px;
  height: 70px;
  background-color: gray;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 30px;
  }
}

.info {
  padding-left: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  .remove_icon {
    cursor: pointer;
  }
}
