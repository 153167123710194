@import "src/style/colors";

.container {
  display: flex;
  padding: 2em 2em 0 0;
}

.info {
  padding-left: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    text-decoration: underline $green-dark;
    color: black;
  }

  .title_container {
    margin-bottom: 0.5em;
  }

  .title {
    font-size: 1.2em;
    font-weight: bold;
  }

  .nominated_badge {
    display: flex;
    padding: 0.25em 0.5em;
    align-items: center;
    justify-items: center;

    color: white;
    font-weight: bold;
    background-color: goldenrod;
    border-radius: 10px;

    .remove_icon {
      cursor: pointer;
      margin-left: 0.25em;
    }
  }

  .controls {
    display: flex;
    button {
      border-radius: 5px;
      background-color: white;
      cursor: pointer;
      font-size: 1.05em;

      &.remove {
        border: 2px solid red;
      }

      &.nominate {
        border: 2px solid $green-dark;
      }

      &:disabled {
        background-color: $sky-light;
        color: gray;
        border-color: gray;
        cursor: default;
      }
    }
  }
}
