@import "src/style/variables";
@import "src/style/colors";

.body {
  display: flex;

  @media (min-width: $md) {
    padding: 2em 2em 0 2em;
  }

  @media (min-width: $lg) {
    padding: 2em 2em 0 2em;
  }

  @media (min-width: $xl) {
    padding: 0em 10em 0em 10em;
  }

  @media (max-width: $md) {
    padding: 1em 1em 0 1em;
    flex-direction: column;
    position: sticky;
  }
}

main {
  flex: 70%;
  margin-top: 1em;
}

.searchbox_container {
  position: sticky;
  top: 0;
  align-self: start;
  background: white;
}

aside {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: start;
  flex: 30%;
  padding: 0em 0em 0em 2em;
  margin-top: 1em;

  .nominated {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1em 2.5em;
  }
}
