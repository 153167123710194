@import "src/style/colors";

.medium {
  width: 100px;
}

.small {
  width: 50px;
}

.unknown_poster {
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;

  &.medium {
    height: 150px;

    img {
      height: 60px;
    }
  }

  &.small {
    height: 75px;

    img {
      height: 24px;
    }
  }
}
